import React from "react"
import { graphql } from "gatsby"
import * as styles from "../../styles/page/second.module.scss"
import specialCharacterReplace from "../../utils/special-character-replace"
import { parseISO, format } from 'date-fns'
import ja from 'date-fns/locale/ja'

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"
import PublicContributionTab from "../../components/parts/public-contribution-tab"

import SortTable from "../../components/parts/sort-table"
import { DateSort } from "../../utils/table-sort"

// markup
const IndexPage = ({data}) => {
  const markdownRemark = data.markdownRemark
  const publicContribution = data.publicContribution.edges

  const { frontmatter } = markdownRemark
  const { title, description, slug } = frontmatter

  const tableHeader = [
    { Header: 'プロジェクト名', accessor: 'title', disableSortBy: true },
    { Header: '予告掲載日', accessor: 'noticedAt', sortType: DateSort},
    { Header: '公募開始日', accessor: 'startedAt', sortType: DateSort},
    { Header: '採択結果', accessor: 'adoptedAt', sortType: DateSort},
    { Header: 'ニュースリリース', accessor: 'release', disableSortBy: true },
  ]

  const tableRows = publicContribution.map(item => {
    const {noticedAt, startedAt, adoptedAt, newsRelease} = item.node
    let release = null;
    let pickupNewsRelease = null
    if (newsRelease !== null ) {
      release = Object.keys(newsRelease).filter(k => newsRelease[k].pc_flag === true)
      pickupNewsRelease = newsRelease[release[0]]
    }

    return {
      title: (
        <div className={styles.flex}>
              <span>{item.node.number[0]}</span>
              <span dangerouslySetInnerHTML={{ __html: specialCharacterReplace(item.node.title) }} />
        </div>
      ),
      noticedAt: (
        <>
          {
          noticedAt !== null && noticedAt.date ?
                noticedAt.link ?
                  <a href={noticedAt.link} target="_blank" rel="noreferrer noopener">
                    {format(parseISO(noticedAt.date), 'yyyy年MM月dd日', {locale: ja})}
                  </a> :
                  format(parseISO(noticedAt.date), 'yyyy年MM月dd日', {locale: ja}) :
                  '-'
          }
        </>
      ),
      startedAt: (
        <>
        {
          startedAt !== null && startedAt.date ? 
            startedAt.link ?
              <a href={startedAt.link} target="_blank" rel="noreferrer noopener">
                {format(parseISO(startedAt.date), 'yyyy年MM月dd日', {locale: ja})}
              </a> :
              format(parseISO(startedAt.date), 'yyyy年MM月dd日', {locale: ja}) :
              '-'
        }
        </>
      ),
      adoptedAt: (
        <>
          { 
          adoptedAt !== null && adoptedAt.date ? 
            adoptedAt.link ? 
              <a href={adoptedAt.link} target="_blank" rel="noreferrer noopener">
                {format(parseISO(adoptedAt.date), 'yyyy年MM月dd日', {locale: ja})}
              </a> :
              format(parseISO(adoptedAt.date), 'yyyy年MM月dd日', {locale: ja}) :
              '-'
          }
        </>
      ),
      release: (
        pickupNewsRelease !== undefined ? 
          pickupNewsRelease !== null ?
            <a href={pickupNewsRelease.link} title={pickupNewsRelease.title} target="_blank" rel="noreferrer noopener">○</a> : '-'
            : '-'
      ),
    }
  })

  return (
    <Layout>
      <Meta 
        title={title}
        description={description}
        path={slug}
      />
      <BreadCrumb
        parentLink="/about/"
        parentTitle="グリーンイノベーション基金事業概要等"
        currentTitle={title}
      />

      <div className={styles.pageTitle}>
        <h1 className={styles.pageTitleHeading}>{title}</h1>
      </div>
      <div className={styles.leadText}>
      </div>

      <main className={styles.pageContents}>
        <PublicContributionTab current="public-contribution" />

        <div className={styles.tableWrapper}>
          <SortTable exClass={`${styles.basicTable} ${styles.publicContribution}`} tableHeader={tableHeader} tableRows={tableRows} summary="公募一覧" />
        </div>
        
      </main>
      
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
    publicContribution: allMicrocmsPublicContribution {
      edges {
        node {
          number
          title
          startedAt {
            date
            link
          }
          noticedAt {
            date
            link
          }
          newsRelease {
            title
            link
            pc_flag
          }
          publishedAt
          adoptedAt {
            link
            date
          }
        }
      }
    }
  }
`